<template>
	<div
		:class="['panel', 'teaser',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row">
					<div class="col">
						<h2>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<div class="row">
				<div v-for="(item, index) in block.items" :key="index" class="col-12 col-lg-6">
					<div v-if="item.image || item.photo_description" class="teaser_image_block theme-inverse">
						<div v-if="item.image">
							<b-img-lazy :src="item.image" />
						</div>
						<div v-if="item.photo_description" class="description">
							{{ item.photo_description }}
						</div>
					</div>

					<p v-if="item.short_description" class="title">
						{{ item.short_description }}
					</p>
					<div v-if="item.description" class="description" v-html="item.description" />
				</div>
			</div>

			<div v-if="isFooterVisible" class="panel_footer">
				<div v-if="block.footer" v-html="block.footer" />
				<!--<panel-footer :block="block"/>-->
			</div>
		</div>
	</div>
</template>

<script>

import panel from '~/mixins/panel';

export default {
	name: 'PanelSimilarColumns',
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	// @import "~/assets/styles/components/home/panel-announces";
	@import "~/assets/styles/components/home/panel-teaser";
</style>
