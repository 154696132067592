var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'teaser',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.block.items),function(item,index){return _c('div',{key:index,staticClass:"col-12 col-lg-6"},[(item.image || item.photo_description)?_c('div',{staticClass:"teaser_image_block theme-inverse"},[(item.image)?_c('div',[_c('b-img-lazy',{attrs:{"src":item.image}})],1):_vm._e(),_vm._v(" "),(item.photo_description)?_c('div',{staticClass:"description"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.photo_description)+"\n\t\t\t\t\t")]):_vm._e()]):_vm._e(),_vm._v(" "),(item.short_description)?_c('p',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.short_description)+"\n\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()])}),0),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[(_vm.block.footer)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.block.footer)}}):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }